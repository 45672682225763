import React from 'react'
import styled from 'styled-components'
import { H3, H5, Text } from '../texts'
import { Header, InlineContainer, StepContainer, Tag, TagsContainer, TextContainer } from './components'

import './magic.css'

const ContainerImage = styled.div`
    @media only screen and (max-width: 1180px) {
        display: none;
    }
`

const Step = (props, ref) => {
    return (
        <StepContainer ref={ref}>
            <InlineContainer>
            <ContainerImage>
                <div className='card'>
                    <Text className='shine' font='Bold' letterSpacing='1px' background='linear-gradient(270deg, #D298FF, #90D0FF);' nonSelect fontSize={22} lineHeight={42}>
                        Coming soon...
                    </Text>
                </div>
            </ContainerImage>
            <TextContainer>
                <Header text='New products'/>
                <H3 font='Semibold' marginTop={10} background='linear-gradient(270deg, #D298FF, #90D0FF);'>Personal projects</H3>
                <H5 font='Regular' customColor='#E7E7E7' marginTop={40}>I'm constantly looking for ways to make our world better and exploring areas for improvement.</H5>
                <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>Some of my projects and ideas are currently under wraps and not yet ready for public disclosure.</H5>
                <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>If you're interested, feel free to contact me by scrolling down.</H5>
                <TagsContainer>
                    <Tag text='PERSONAL' />
                    <Tag text='IN DEVELOPMENT' />
                    <Tag text='COMING SOON' />
                </TagsContainer>
            </TextContainer>
            </InlineContainer>
        </StepContainer>
    )
}

export const MagicStep = React.memo(React.forwardRef(Step))