import React from "react";
import styled from "styled-components";
import { H3, H5 } from "../texts";
import {
  Header,  
  InlineContainer,
  StepContainer,
  Tag,
  TagsContainer,
  TextContainer,
} from "./components";
import { StaticImage } from "gatsby-plugin-image";

const MobileImage = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  margin-top: 20px;

  @media only screen and (min-width: 1180px) {
    display: none;
  }
`;

export const HorizontalContainerImage = styled.div`
    width: 100%;
    max-width: 600px;
    border-radius: 20px;

    @media only screen and (max-width: 1180px) {
        display: none;
    }
`

const Step = (props, ref) => {
  return (
    <StepContainer ref={ref}>
      <InlineContainer>
        <MobileImage>
          <StaticImage
            placeholder="blurred"
            style={{ borderRadius: 20 }}
            src="../../images/cityweft.png"
            alt='cityweft screenshot'
          />
        </MobileImage>
        {/* <MobileVideo controls={false} autoPlay muted loop>
                    <source src={DesignVideo} type="video/mp4" />
                </MobileVideo>            */}
        <TextContainer left>
          <Header text="Cityweft" />
          <H3
            font="Semibold"
            marginTop={10}
            background="linear-gradient(270deg, #D298FF, #90D0FF);"
          >
            Founder
          </H3>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={40}>
            A web app and API that provides high-quality urban context with
            precise geometry, including detailed roof structures.
          </H5>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={10}>
            This project involves the creation of complex algorithms to ensure
            accuracy and quality of geometry.
          </H5>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={10}>
            Cityweft aims to deliver exceptional cityscape geometry for various
            applications.
          </H5>
          <TagsContainer>
            <Tag text="PERSONAL" />
            <Tag text="WEB APP" />
            <Tag text="API" />
            <Tag text="IN BETA" />
            <Tag text="ACTIVE DEVELOPMENT" />
          </TagsContainer>
        </TextContainer>
        <HorizontalContainerImage>
          <StaticImage
            placeholder="blurred"
            style={{ borderRadius: 20 }}
            src="../../images/cityweft.png"
            alt='cityweft screenshot'
          />
        </HorizontalContainerImage>
        {/* <Video controls={false} autoPlay muted loop>
                    <source src={DesignVideo} type="video/mp4" />
                </Video> */}
      </InlineContainer>
    </StepContainer>
  );
};

export const CityweftStep = React.memo(React.forwardRef(Step));
