import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { H3, H5 } from '../texts'
import { Header, InlineContainer, StepContainer, TagsContainer, Tag, TextContainer } from './components'

const ContainerImage = styled.div`
    /* width: 600px; */
    flex-shrink: 0;

    @media only screen and (max-width: 1180px) {
        display: none;
    }
`

const Step = (props, ref) => {
    return (
        <StepContainer ref={ref}>
            <InlineContainer>
                <TextContainer left>
                    <Header text='Apple Store Apps' />
                    {/* <H5 fontSize={16} customColor='#bdc3c7'>apr 2021 - sep 2023</H5> */}
                    <H3 font='Semibold' marginTop={10} background='linear-gradient(270deg, #D298FF, #90D0FF);'>Creator of several iOS apps</H3>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={40}>I've developed several iOS apps, each of which generates revenue.</H5>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>These apps range from multicooker recipes to IQ tests, highlighting my versatility in app creation</H5>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>My primary focus has been on building profitable and practical solutions.</H5>
                <TagsContainer>
                    {/* <Tag text='GROSSING' /> */}
                    <Tag text='IOS APPS' />
                    <Tag text='PROFITABLE' />
                    <Tag text='HIGH-RATED' />
                    <Tag text='BOOTSTRAPPED' />
                    {/* <Tag text='>600$ MRR' /> */}
                </TagsContainer>
                </TextContainer>
                <ContainerImage>
                    <StaticImage placeholder="blurred" alt='accusoft ou app screenshot' style={{ marginTop: 50 }} width={230} src="../../images/app-store-1.png" />
                    <StaticImage placeholder="blurred" alt='accusoft ou app screenshot'  style={{ marginBottom: 50 }} width={230} src="../../images/app-store-3-test.png" />
                    <StaticImage placeholder="blurred" alt='accusoft ou app screenshot'  style={{ marginTop: 50 }} width={230} src="../../images/personality-green.png" />
                </ContainerImage>
            </InlineContainer>    
        </StepContainer>
    )
}

export const AppStoreAppsStep = React.memo(React.forwardRef(Step))