import React from 'react'
import styled from 'styled-components'
import { H3, H5 } from '../texts'
import { TextContainer,  Header, InlineContainer, StepContainer, TagsContainer, Tag } from './components'
import DesignVideo from '../../assets/design-video.mp4'

const Video = styled.video`
    width: 100%;
    max-width: 500px;
    border-radius: 20px;

    @media only screen and (min-width: 1180px) {
        max-width: 50%;
    }
`

const Step = (props, ref) => {
    return (
        <StepContainer ref={ref}>
            <InlineContainer>
            {/* <MobileVideo controls={false} autoPlay muted loop>
                    <source src={DesignVideo} type="video/mp4" />
                </MobileVideo>    */}
                <Video controls={false} autoPlay muted loop>
                    <source src={DesignVideo} type="video/mp4" />
                </Video>
                <TextContainer right>
                    <Header text='Kreo'/>
                    <H3 font='Semibold' marginTop={10} background='linear-gradient(270deg, #D298FF, #90D0FF);'>Lead Frontend Developer</H3>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={40}>In this role, I contributed significantly to the Kreo Modular and Kreo Design projects, handling both frontend and backend development along with CI/CD.</H5>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>
                        I engineered a sophisticated web application featuring an interactive 2D and 3D building editor, dynamic reporting, and advanced algorithms.
                    </H5>
                    <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>I was responsible for most aspects of the application's development showcased in the video.</H5>
                    {/* <H5 font='Regular' customColor='#E7E7E7' marginTop={5}>Most of what you see in the video done by me.</H5> */}
                {/* <H5 marginTop={30}>App is used in 160+ countries</H5> */}
                <TagsContainer>
                    <Tag text='ENTERPRISE' />
                    <Tag text='ENGINEERING' />
                    <Tag text='LEADERSHIP' />
                    <Tag text='WEB APP' />
                    <Tag text='FULL STACK' />
                </TagsContainer>
                </TextContainer>

            </InlineContainer>
            
        </StepContainer>
    )
}

export const KreoStep = React.memo(React.forwardRef(Step))