import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { H3, H5 } from '../texts'
import { Header, HorizontalContainerImage, InlineContainer, StepContainer, Tag, TagsContainer, TextContainer } from './components'

const Step = (props, ref) => {
    return (
        <StepContainer ref={ref}>
            <InlineContainer>
            <HorizontalContainerImage>
                {/* <StaticImage placeholder="blurred"  style={{ marginBottom: 100, marginRight: 50 }} width={260} src="../../images/spazier-1.png" /> */}
                <StaticImage placeholder="blurred" alt='sketchup ipad app screenshot' src="../../images/context-sketchup-ipad.png" />
            </HorizontalContainerImage>
            <TextContainer right>
                <Header text='Context for SketchUp'/>
                <H3 font='Semibold' marginTop={10} background='linear-gradient(270deg, #D298FF, #90D0FF);'>Founder</H3>
                <H5 font='Regular' customColor='#E7E7E7' marginTop={40}>I've crafted the "Context For SketchUp" iPad app to provide seamless urban context integration for SketchUp users.</H5>
                <H5 font='Regular' customColor='#E7E7E7' marginTop={10}>This standalone app allows users to export any part of the world directly to SketchUp with a single tap.</H5>
                <TagsContainer>
                    <Tag text='PERSONAL' />
                    <Tag text='IPAD OS APP' />
                    <Tag text='PROFITABLE' />
                    <Tag text='BOOTSTRAPPED' />
                </TagsContainer>
            </TextContainer>
            </InlineContainer>
        </StepContainer>
    )
}

export const ContextSketchupStep = React.memo(React.forwardRef(Step))