import React from "react";
import styled from "styled-components";
import { H2, H3, Text } from "../texts";
import { StepContainer } from "./components";

const InlineContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextContainer = styled.div`
  @media only screen and (max-width: 1180px) {
    width: 100%;
    padding-bottom: 100px;
    h3 {
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 1600px) {
    max-width: 700px;

    p {
      font-size: 28px;
      line-height: 36px;
    }

    h3 {
      font-size: 40px;
      line-height: 46px;
    }
  }
`;

const Step = (props, ref) => {
  return (
    <StepContainer ref={ref}>
      <InlineContainer>
        <RowContainer>
          <InlineContainer>
            <H2 textAlign="center" font="Bold">
              LET'S
            </H2>
            <H2
              font="Bold"
              background="linear-gradient(270deg, #D298FF, #90D0FF);"
            >
              &nbsp;{"CONNECT!"}
            </H2>
          </InlineContainer>
          <TextContainer>
            <Text textAlign="center" customColor="#E7E7E7" marginTop={10}>
              Are you an investor and interested in my projects?
            </Text>
            <Text textAlign="center" customColor="#E7E7E7">
              Do you need professional developer consulting?
            </Text>
            <H3 textAlign="center" marginTop={60} font="Bold">
              contact@sobolevski.eu
            </H3>
          </TextContainer>
        </RowContainer>
      </InlineContainer>
    </StepContainer>
  );
};

export const ContactStep = React.memo(React.forwardRef(Step));
