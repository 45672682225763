// globalStyles.js
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    h5 { margin: 0 }
  }

  * {
    box-sizing: border-box;
  }
`;
 
export default GlobalStyle;