import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
 0% {
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(360deg); 
  }
`

const Container = styled.div`
  pointer-events: none;
  position: absolute;
  top: ${p => p.top ? `${p.position}px` : 'unset'};
  bottom: ${p => p.bottom ? `${p.position}px` : 'unset'};
  left: ${p => p.left};
  z-index: -1;
  height: 50vh;
  width: 40vh;
  border-radius: 50%;
  filter: ${p => `blur(${p.blur}px)`};
  /* background-image: linear-gradient(132deg, #833ab4, #fd1d1d, #fcb045); */
  background-image: linear-gradient(132deg, #5ddcff, #3c67e3 43%, #4e00c2);
  opacity: .5;
  transition: all 1s ease 0s; 
  animation: ${spin} ${p => p.speed}s linear infinite;

  @media only screen and (max-width: 1180px) {
    display: ${p => p.blockMobile ? 'none' : 'block'};
    }
`

export const Blob = ({ pageIndex, left, top, bottom, speed, blur, blockMobile }) => {
    const [position, setPosition] = useState(0)
    useEffect(() => {
      setPosition((window.innerHeight / (9 / pageIndex) - (window.innerHeight / 2)))
    }, [pageIndex])
    
    return (
        <Container blockMobile={blockMobile} speed={speed} blur={blur} position={position} left={left} top={top} bottom={bottom} ></Container>
    )
}