import React from 'react'
import styled, { keyframes } from 'styled-components'

import { Blob } from '../blob'
import { H2, H3, H5, H4 } from '../texts'
import ArrowIcon from '../../assets/icons/arrow.svg'
import ArrowIconCopy from '../../assets/icons/arrow-copy.svg'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: fit-content;
    padding: 0% 5% 40px;
    align-items: flex-start;
    flex-shrink: 0;

    @media only screen and (min-width: 1180px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        padding: 5%;
        -webkit-scroll-snap-align: start;
        scroll-snap-align: start; 
    }

     @media only screen and (max-width: 1180px) {
       align-items: center;
    }
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
    margin-right: 60px;
    
    h2 {
        width: max-content;
    }

    @media only screen and (max-width: 1180px) {
        margin-right: 0px;
        align-items: center;
        margin: 60px 0px;
    }
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h6 {
        display: none;
    }
    
    @media only screen and (max-width: 1180px) {
        width: fit-content;
        max-width: 500px;
        h6 {
            display: block;
        }
    }
`

const ScrollDowns = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 34px;
  height: 55px;

  @media only screen and (max-width: 1180px) {
    display: none;
}
`

const Mouse = styled.div`
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
`

const scroll = keyframes`
 0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
`

const Scroller = styled.div`
     width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: ${scroll};
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
`

// const UpButton = styled.button`
//     cursor: pointer;
//     opacity: ${p => p.pageIndex === 1 ? 0 : 1 };
//     position: absolute;
//     height: 50px;
//     width: 50px;
//     bottom: 20px;
//     right: 20px;
//     transform: rotate(-90deg);
//     transition: opacity 1.5s ease-in-out;
    
//     svg {
//         height: 50px;
//     }
// `

const ContactMe = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    margin-top: 60px;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

    @media only screen and (max-width: 1180px) {
        display: none;
    }
`

const ContactMeMobile = styled(ContactMe)`
    display: inline-flex;
    width: 160px;
    margin-top: 10px;

    svg {
        width: 30px;
    }

    h4 {
        font-size: 20px;
    }

    @media only screen and (min-width: 1180px) {
        display: none;
    }
`

const InlineContainer = styled.div`
    display: inline-flex;
`

const Step = ({ pageIndex, scrollToContacts }) => {
    return (
        <Container>
            <LeftContainer data-aos="zoom-in-down">
                <InlineContainer>
                    <H2 font="Bold" >HELLO, I'M</H2><H2 font="Bold" background='linear-gradient(270deg, #D298FF, #90D0FF);'>&nbsp;{'SEMION!'}</H2>
                </InlineContainer>
                <ContactMe mobile={false} onClick={() => scrollToContacts()}>
                    <H4 nonSelect font="Semibold" background='linear-gradient(270deg, #D298FF, #90D0FF);'>Contact me</H4>
                    <ArrowIcon/>
                </ContactMe>
            </LeftContainer>
            <RightContainer data-aos="zoom-in-up">
                <H3 font="Semibold">A passionate <span style={{ color: '#90D0FF' }}>innovator</span> dedicated</H3>
                <H3 font="Semibold" marginTop={6} >to creating innovative solutions <span style={{ color: '#D298FF' }}>with 8 years</span></H3>
                <H3 font="Semibold" marginTop={6} >of commercial engineering experience.</H3>
                <H5 marginTop={36} fontSize={22} font="Regular" customColor='#E7E7E7'>I know how to build high quality products from scratch.</H5>
                <H5 marginTop={8} fontSize={22} font="Regular" customColor='#E7E7E7'>Estonia - Denmark</H5>
                {/* <H6 marginTop={20} font="Regular" customColor='#E7E7E7'>Use a desktop device version to see more content.</H6> */}
                <ContactMeMobile mobile onClick={() => scrollToContacts()}>
                    <H4 nonSelect font="Semibold" background='linear-gradient(270deg, #D298FF, #90D0FF);'>Contact me</H4>
                    <ArrowIconCopy/>
                </ContactMeMobile>
            </RightContainer>
            <Blob left={'0%'} speed={15} blur={60} pageIndex={pageIndex} top/>
            <Blob blockMobile left={'70%'} speed={20} blur={80} pageIndex={pageIndex} bottom/>
            {pageIndex === 1 && <ScrollDowns>
                <Mouse>
                    <Scroller></Scroller>
                </Mouse>
                </ScrollDowns>}
            {/* <UpButton pageIndex={pageIndex} onClick={() => scrollToTop()}><ArrowIcon/></UpButton> */}
        </Container>
    )
}

export const MainStep = React.memo(Step, (a, b) => {
    if (a.pageIndex === b.pageIndex) {
        return true
    }
})