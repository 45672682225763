import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { H3, H5 } from '../texts'
import { TextContainer,  Header, InlineContainer, StepContainer, TagsContainer, Tag, HorizontalContainerImage } from './components'

const Step = (props, ref) => {
    return (
        <StepContainer ref={ref}>
            <InlineContainer>
            <HorizontalContainerImage>
                <StaticImage placeholder="blurred" style={{ borderRadius: 20 }} src="../../images/pipedrive-1000-employees.jpg" alt='pipedrive 1000 employees photo' />
            </HorizontalContainerImage>
            <TextContainer right>
                <Header text='Pipedrive' />
                <H3 marginTop={10} font='Semibold' background='linear-gradient(270deg, #D298FF, #90D0FF);'>Senior Software Engineer</H3>
                <H5 marginTop={40} font='Regular' customColor='#E7E7E7'>At Pipedrive, a leading tech unicorn, I gained invaluable experience primarily in frontend development.</H5>
                <H5 marginTop={10} font='Regular' customColor='#E7E7E7'>As a Senior Developer on the Platform Team, I led high-impact initiatives like new tools onboarding and creating a reusable library adopted across the organization.</H5>
                {/* <H5 marginTop={5} font='Regular' customColor='#E7E7E7'>Operated within the platform team, overseeing the entire web application.</H5> */}
                <TagsContainer>
                    <Tag text='ENTERPRISE' />
                    <Tag text='ENGINEERING' />
                    <Tag text='FRONTEND' />
                    <Tag text='UNICORN' />
                    <Tag text='LEADERSHIP' />
                </TagsContainer>
            </TextContainer>
            </InlineContainer>
        </StepContainer>
    )
}

export const PipedriveStep = React.memo(React.forwardRef(Step))