import React from 'react'
import styled from 'styled-components'
import { H2, TinyText } from '../texts'

export const StepContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: ${p => p.alignItems || 'center'};
    justify-content: center;
    padding: 5%;
    box-sizing: border-box;
    -webkit-scroll-snap-align: start;
    scroll-snap-align: start;
    flex-shrink: 0;

    @media only screen and (max-width: 1180px) {
        -webkit-scroll-snap-align: none;
        scroll-snap-align: none;
        height: max-content;
        margin-bottom: 60px;
        align-items: center;
    }

    /* @media only screen and (min-width: 1180px) {
       max-width: 1400px;
    } */
`

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    width: fit-content;
    padding: 5px 15px;
    border: 1px solid #D298FF;
    border-radius: 15px;
`

const HeaderContainer = styled.div`
    width: 100%;

    @media only screen and (max-width: 1180px) {
        margin-top: 20px;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;

    @media only screen and (min-width: 1180px) {
        margin-left: ${p => p.right ? 40 : 0}px;
        margin-right: ${p => p.left ? 40 : 0}px;
    }

    @media only screen and (min-width: 1500px) {
        max-width: 700px;

        h5 {
            font-size: 22px;
            line-height: 28px;
        }

        h3 {
            font-size: 28px;
            line-height: 38px;
        }
    }

    @media only screen and (max-width: 1180px) {
        h3 { 
            margin-top: 20px;
        }
    }
`

export const TagsContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

export const InlineContainer = styled.div`
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;

    @media only screen and (max-width: 1180px) {
        display: flex;
        flex-direction: column;
    }
`

export const HorizontalContainerImage = styled.div`
    width: 100%;
    max-width: 500px;
    border-radius: 20px;

    @media only screen and (min-width: 1180px) {
        max-width: 40%;
    }
`


export const Tag = ({text}) => {
    return (
        <TagContainer>
            <TinyText nonSelect background='linear-gradient(270deg, #D298FF, #90D0FF);'>{text}</TinyText>
        </TagContainer>
    )
}

export const Header = ({ text }) => {
    return (<HeaderContainer>
        <H2 font='Bold'>{text}</H2>
    </HeaderContainer>)
}