import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { H3, H5 } from "../texts";
import {
  Header,
  InlineContainer,
  StepContainer,
  Tag,
  TagsContainer,
  TextContainer,
} from "./components";

const MobileImage = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  margin-top: 20px;

  @media only screen and (min-width: 1180px) {
    display: none;
  }
`;

const ContainerImage = styled.div`
  /* width: 50%; */
  flex-shrink: 0;

  @media only screen and (max-width: 1180px) {
    display: none;
  }
`;

const Step = (props, ref) => {
  return (
    <StepContainer ref={ref}>
      <InlineContainer>
        <MobileImage>
          <StaticImage
            placeholder="blurred"
            style={{ borderRadius: 20 }}
            src="../../images/accudriver-web.png"
            alt="accudriver web screenshot"
          />
        </MobileImage>
        <TextContainer left>
          <Header text="Accudriver" />
          <H3
            marginTop={10}
            font="Semibold"
            background="linear-gradient(270deg, #D298FF, #90D0FF);"
          >
            Founder
          </H3>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={40}>
            Web, iOS, Android app for comparing traffic rules. Accudriver is my biggest solo project. I've developed each piece of
            the web and mobile apps.
          </H5>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={10}>
            Every day, the app helps people worldwide understand traffic rules
            and makes our world safer!
          </H5>
          <H5 font="Regular" customColor="#E7E7E7" marginTop={10}>
            People from more than a hundred countries use the application every
            month.
          </H5>
          <TagsContainer>
            <Tag text="PERSONAL" />
            <Tag text="> 50.000 MAU" />
            <Tag text="PROFITABLE" />
            <Tag text="WEB APP" />
            <Tag text="MOBILE APP" />
          </TagsContainer>
        </TextContainer>
        <ContainerImage>
          <StaticImage
            placeholder="blurred"
            style={{ marginRight: 20, marginTop: 50 }}
            width={200}
            src="../../images/accudriver-2.png"
            alt="accudriver mobile screenshot"
          />
          <StaticImage
            placeholder="blurred"
            style={{ marginRight: 20 }}
            width={230}
            alt="accudriver mobile screenshot"
            src="../../images/accudriver-1.png"
          />
          <StaticImage
            placeholder="blurred"
            width={200}
            style={{ marginTop: 50 }}
            alt="accudriver mobile screenshot"
            src="../../images/accudriver-3.png"
          />
        </ContainerImage>
      </InlineContainer>
    </StepContainer>
  );
};

export const AccudriverStep = React.memo(React.forwardRef(Step));
