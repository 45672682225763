import React from 'react'
import styled from 'styled-components'
import PipedriveIcon from '../../assets/icons/pipedrive.svg'
import KreoHorizontalIcon from '../../assets/icons/kreo-horizontal.svg'
import CityweftIcon from '../../assets/icons/cityweft.svg'
import AppStoreApps from '../../assets/icons/app-store.svg'
import './style.css'
import { StepContainer } from '../steps/components'
import { H2, H3, H5, Text } from '../texts'

const InsideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    svg, img {
        width: 80%;
        max-height: 94px;
    }   
`

const Link = styled.div`
    text-decoration: none;
    border-radius: 0.75em;
    transition: transform 350ms ease-in-out;
    overflow: hidden;
    background-color: #282828;

    &:hover, &:focus-visible {
        transform: scale(1.1);
        box-shadow: 0 0 1.5rem #4A4A4A;

        h1, h2, h3, h4, h5, h6 {
            background: linear-gradient(270deg, #D298FF, #90D0FF);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`

const GridTextContainer = styled.div`
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: 1180px) {
        margin-right: 40px;

        h5 {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.5px;
            /* line-height: 36px; */
        }
    }

    @media only screen and (max-width: 1180px) {
        max-width: 500px;        
    }

    @media only screen and (min-width: 1600px) {
        width: 600px;
        h5 {
            font-size: 24px;
            line-height: 36px;
        }

        h3 {
            font-size: 40px;
            line-height: 46px;
        }
    }
`

const Step = ({scrollToStep}) => {
    return (
        <StepContainer alignItems='flex-start'>
            <div className="feature-grid-container grid grid--columns">
                <GridTextContainer >
                    <H2 font="Semibold">Activity</H2>
                    <H3 marginTop={40} lineHeight={52} font='Semibold' background='linear-gradient(270deg, #D298FF, #90D0FF);'>I'm a passionate innovator dedicated to developing innovative solutions.</H3>
                    <H5 marginTop={30} font='Regular'>With a strong foundation in software engineering, including expertise in application architecture design, frontend, and mobile development, I excel at transforming ideas into impactful products.</H5>
                    {/* <H5 marginTop={5} font='Regular'>Frontend, Backend, DevOps and Mobile development.</H5> */}
                </GridTextContainer>
                <div className="grid feature-grid">
                    <Link data-no-blobity onClick={() => scrollToStep('magic')}>
                        <InsideContainer>
                            <Text nonSelect opacity={0.05} fontSize={96} lineHeight={96} font="Semibold">?</Text>
                        </InsideContainer>
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('pipedrive')}>
                    <InsideContainer><PipedriveIcon/></InsideContainer>
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('accudriver')}>
                        {/* <InsideContainer><AccudriverIcon/></InsideContainer> */}
                        <InsideContainer><H5 fontSize={22} nonSelect font='Semibold'>accudriver</H5></InsideContainer>
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('appStoreApps')}>
                        <InsideContainer><AppStoreApps/></InsideContainer>
                        {/* <InsideContainer><StaticImage src="images/recipes.png" alt=""/></InsideContainer>                         */}
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('kreo')}>
                        <InsideContainer><KreoHorizontalIcon/></InsideContainer>
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('cityweft')}>
                            {/* <InlineContainer><H2 font='Semibold'>pipedrive</H2></InlineContainer> */}
                        <InsideContainer><CityweftIcon/></InsideContainer>
                    </Link>
                    <Link data-no-blobity onClick={() => scrollToStep('contextSketchup')}>
                        <InsideContainer><H5 nonSelect font="Semibold">Context</H5><H5 nonSelect font="Semibold">For SketchUp</H5> </InsideContainer>
                    </Link>
                </div>
            </div>
        </StepContainer>
    )
}

export const Picker = React.memo(Step)