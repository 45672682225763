import AOS from 'aos'
import * as React from "react"
import useBlobity from 'blobity/lib/useBlobity';
import styled from 'styled-components'
import { Helmet } from "react-helmet"
import { Picker } from "../components/picker";
import { MainStep } from "../components/steps/main";
import { AccudriverStep } from "../components/steps/accudriver";
import { KreoStep } from '../components/steps/kreo';
import { PipedriveStep } from '../components/steps/pipedrive';
import { CityweftStep } from '../components/steps/cityweft';
import { ContextSketchupStep } from '../components/steps/context-sketchup'
import { ContactStep } from '../components/steps/contact'
import { MagicStep } from '../components/steps/magic'
import GlobalStyle from '../components/globalStyles'

import 'aos/dist/aos.css'; 
import { AppStoreAppsStep } from '../components/steps/app-store';

const initiaBlobityOptions = { color: 'rgb(255, 255, 255)', dotColor: 'white', invert: true, radius: 8 };

const Wrapper = styled.div` 
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IndexPage = () => {
  const blobityInstance = useBlobity(initiaBlobityOptions);

  React.useEffect(() => {
      if (blobityInstance.current) {
          // @ts-ignore for debugging purposes or playing around
          window.blobity = blobityInstance.current;
      }
  }, [blobityInstance]);

  const [offsetTop, setOffsetTop] = React.useState(0)
  const [pageIndex, setPageIndex] = React.useState(1)

  React.useEffect(() => {
    AOS.init({
      duration: 1000
    });
    AOS.refresh();
  }, [])

  React.useEffect(() => {
    setPageIndex(Math.ceil((offsetTop + window.innerHeight) / window.innerHeight))
  }, [offsetTop])
  
  React.useLayoutEffect(() => {
    function updateScrollPosition(e) {
      setOffsetTop(e.target.scrollTop)
    }

    if (scroller && scroller.current) {
        scroller.current.addEventListener("scroll", updateScrollPosition, false);
        return function cleanup() {
             scroller.current.removeEventListener("scroll", updateScrollPosition, false);
        };
    }
}, []);
  const pipedriveRef = React.useRef()
  const accudriverRef = React.useRef()
  const kreoRef = React.useRef()
  const appStoreAppsRef = React.useRef()
  const contextSketchupRef = React.useRef()
  const cityweftRef = React.useRef()
  const contactRef = React.useRef()
  const magicrRef = React.useRef()
  const scroller = React.useRef()
  const refs = React.useMemo(() => ({
    pipedrive: pipedriveRef,
    accudriver: accudriverRef,
    kreo: kreoRef,
    appStoreApps: appStoreAppsRef,
    contextSketchup: contextSketchupRef,
    cityweft: cityweftRef,

    magic: magicrRef,
    contact: contactRef,
  }), [])
  const scrollToStep = refName => {
    const ref = refs[refName]
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setPageIndex(2)
    }
  }

  // const scrollToTop = () => {
  //   console.log('scrollToTop', scrollToTop)
  //   if (scroller && scroller.current) {
  //     scroller.current.scrollIntoView({ behavior: 'smooth' })
  //   }
  //   return null
  // }

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Semion Sobolevski</title>
          <link rel="canonical" href="http://sobolevski.eu" />
          <meta name="image" property="og:image" content="https://sobolevski.eu/og.png" />
          <meta name="author" content="Semion Sobolevski" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:width" content="320" />
          <meta property="og:description" content="Semion Sobolevski personal page. Online Enterpreneur and Software Engineer with 7 years of commercial experience." />
        </Helmet>
      <GlobalStyle />
      <Wrapper ref={scroller}>
          <MainStep pageIndex={pageIndex} scrollToContacts={() => scrollToStep('contact')} />
          <Picker scrollToStep={scrollToStep} />
          <CityweftStep ref={cityweftRef} />
          <PipedriveStep ref={pipedriveRef} />
          <AccudriverStep ref={accudriverRef} />
          <KreoStep ref={kreoRef} />          
          <AppStoreAppsStep ref={appStoreAppsRef} />
          <ContextSketchupStep ref={contextSketchupRef} />
          <MagicStep ref={magicrRef} />
          <ContactStep ref={contactRef}/>
      </Wrapper>
    </>
  )
}

export default IndexPage
