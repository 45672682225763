import styled, { css } from 'styled-components'

const GenericText = css`
  font-weight: normal;
  font-family: ${props => props.font || 'Regular'}, Trebuchet MS, Helvetica, Verdana, Arial, sans-serif;
  color: ${p => p.customColor || 'white'};
  user-select: ${props => (props.nonSelect ? 'none' : 'auto')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : 'normal'};
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  ${p =>
    p.withEllipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
  margin-top: ${p => p.marginTop || 0}px;
  background: ${p => p.background || 'none'};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${p => p.background ? 'transparent' : 'unset'};
  opacity: ${p => p.opacity || 1};
  /* -webkit-font-smoothing: antialiased; */
  -webkit-font-smoothing: subpixel-antialiased;
`

export const H1 = styled.h1`
  ${GenericText}
  font-size: 60px;
  line-height: 90px;
`

export const H2 = styled.h2`
  ${GenericText}
  font-size: 48px;
  line-height: ${props =>
    props.lineHeight ? `${props.lineHeight}px` : '74px'};  


  @media only screen and (max-width: 1180px) {
    font-size: 28px;
    line-height: ${props =>
      props.lineHeight ? `${props.lineHeight}px` : '36px'};  
  }
`

export const H3 = styled.h3`
  ${GenericText}
  font-size: 24px;
  line-height: 30px;

  /* @media only screen and (max-width: 1180px) {
    font-size: 24px;
    line-height: 30px;
  } */
`

export const H4 = styled.h4`
  ${GenericText}
  font-size: 30px;
  line-height: 45px;
`

export const H5 = styled.h5`
  ${GenericText}
  letter-spacing: 0.25px;
  font-size: ${p => p.fontSize || 18}px;
  line-height: 23px;
`

export const H6 = styled.h6`
  ${GenericText}
  font-size: 18px;
  line-height: 25px;
`
export const Text = styled.p`
  ${GenericText}
  font-size: ${props => props.fontSize || 17}px;
  line-height: ${p => p.lineHeight || 24}px;
`

export const TinyText = styled.p`
  ${GenericText}
  font-size: 13px;
  line-height: 17px;
`
